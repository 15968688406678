import React, { Component } from "react";
import { Container, Row } from "reactstrap";

//Import Section Title
import SectionTitle from "../common/section-title";

//Import Pricing
import CapabilitiesBox from "./Capabilities-box";

class Capabilities extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cases: [
        {
          title: "Confidential Analytics",
          desc: "Enable secure and controlled environments where multiple parties can collaborate on data analysis without directly sharing raw data, ensuring privacy and compliance.",
          icon: "pe-7s-box2",
          link: "/platform"
        },
        {
          title: "Confidential AI",
          desc: "Supports the development and deployment of AI models in a manner that safeguards data confidentiality, allowing businesses to harness AI capabilities while protecting sensitive information.",
          icon: "pe-7s-science",
          link: "/platform"
        }
      ],
    };
  }

  render() {
    return (
      <React.Fragment>
        <section className="section pt-6" id="journeys">
          <Container>
            {/* section title */}
            <SectionTitle
              title="Data Collaboration Gateway"
              />
              <center>
              <img
                    src="assets/images/product/solution-video.gif"
                    alt="confidential data collaboration platform"
                    width="100%"
                  />
              </center>
            <br/><br/>
            
            <Row>
              {/* cases box */}
              <CapabilitiesBox cases={this.state.cases} />
            </Row>
            <br/> <br/>  <br/>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Capabilities;
