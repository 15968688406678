import React, { Component } from "react";
import { Container, Row } from "reactstrap";

//Import Section Title
import SectionTitle from "../common/section-title";

//Import Pricing
import CasesBox from "./articles-box";

class Cases extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cases: [
        {
          title: "The Future of Collaborative Fraud Prevention",
          desc: "Collaborative efforts to combat fraud have emerged as a beacon of hope.",
          img: "<img width='100%' src='assets/images/blog/blog4.png'/>",
          link:"/leveraging-regulations-the-future-of-collaborative-fraud-prevention"
        },
        {
          title: "Data Collaboration: The Backbone of Open Finance",
          desc: "Open Finance vs. Open Banking, this is where data collaboration comes into play.",
          img: "<img width='100%' src='assets/images/blog/article1.png'/>",
          link:"/data-collaboration-the-backbone-of-open-finance"
        },
        {
          title: "The new business opportunitinies<br/><br/>",
          desc: "Open up new business opportunities with confidential data & AI collaboration.",
          img: "<img width='100%' src='assets/images/blog/blog3.png'/>",
          link:"/assets/documents/Datavillage-introduction-Business.pdf"
        },
        {
          title: "Data collaboration for intermediaries<br/><br/>",
          desc: "The new data economy - the notion and role of data intermediaries.",
          img: "<img width='100%' src='assets/images/blog/blog2.png'/>",
          link:"/assets/documents/Datavillage-introduction-Data-intermediary.pdf"
        },
        {
          title: "Confidential data collaboration<br/><br/>",
          desc: "Data collaboration is not data sharing - The foundations of confidential data collaboration.",
          img: "<img width='100%' src='assets/images/blog/blog1.png'/>",
          link:"/assets/documents/Datavillage-introduction-Technical.pdf"
        },
      ],
    };
  }

  render() {
    return (
      <React.Fragment>
        <section className="section" id="pricing">
          <Container>
            {/* section title */}
            <SectionTitle
              title="Explore our latest insights"
              />
            <Row>
              {/* cases box */}
              <CasesBox cases={this.state.cases} />
            </Row>
          </Container>
          
        </section>
      </React.Fragment>
    );
  }
}

export default Cases;
