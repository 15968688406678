import React, { Component,useRef } from "react";
import { Container, Row, Col } from "reactstrap";
import { FormErrors } from './form-errors';
import emailjs from '@emailjs/browser';
//Import Svg icon
import SvgIcon from "../common/svg-icon";

//Import Section Title
import SectionTitle from "../common/section-title";

class DemoForm extends Component {
   
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      email: '',
      company: '',
      phone: '',
      subject: '',
      description: '',
      formErrors: { name: '',  company: '',email: '', phone: '',subject: '', description: ''},
      emailValid: false,
      nameValid: false,
      subjectValid: false,
      descriptionValid: false,
      formValid: false
    }

    this.handleSubmit = this.handleSubmit.bind(this);      
  }


  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({[name]: value},
                  () => { this.validateField(name, value) });
  }

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let emailValid = this.state.emailValid;
    let nameValid = this.state.nameValid;
    let subjectValid = this.state.subjectValid;
    let descriptionValid = this.state.descriptionValid;
    let companyValid = this.state.companyValid;
    let phoneValid = this.state.phoneValid;

    switch(fieldName) {
      case 'name':
        nameValid = value.length >= 5;
        if(nameValid)
          fieldValidationErrors.name=''
        else
          fieldValidationErrors.name = 'Name is too short';
        break;
        case 'company':
          companyValid = value.length >= 5;
          if(companyValid)
            fieldValidationErrors.company=''
          else
            fieldValidationErrors.company = 'Company is too short';
          break;
      case 'email':
          emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
          if(emailValid)
            fieldValidationErrors.email=''
          else
            fieldValidationErrors.email = 'Email is invalid';
          break;
      case 'phone':
            phoneValid = value.length >= 6;
            if(phoneValid)
              fieldValidationErrors.phone=''
            else
              fieldValidationErrors.phone = 'Phone is invalid';
            break;
      case 'subject':
            subjectValid = value.length >= 10;
            if(subjectValid)
              fieldValidationErrors.subject=''
            else
                fieldValidationErrors.subject = 'Subject is too short';
            break;
      case 'description':
              descriptionValid = value.length >= 10;
              if(descriptionValid)
                fieldValidationErrors.description=''
              else
                fieldValidationErrors.description = 'Description is too short';
              break;
      default:
        break;
    }
    this.setState({formErrors: fieldValidationErrors,
                    nameValid: nameValid,
                    companyValid: companyValid,
                    emailValid: emailValid,
                    phoneValid: phoneValid,
                    subjectValid: subjectValid,
                    descriptionValid: descriptionValid,
                  }, this.validateForm);
  }

  validateForm() {
    this.setState({formValid: this.state.nameValid && this.state.companyValid && this.state.emailValid  && this.state.phoneValid && this.state.subjectValid  && this.state.descriptionValid});
  }

  errorClass(error) {
    return(error.length === 0 ? '' : 'has-error');
  }


  handleSubmit(event) {
    event.preventDefault();
    if(this.state.formValid){
      event.preventDefault();
          emailjs.sendForm('service_0aoedcg', 'template_l1l0mpr', event.target, '58WUIsqEKODPAeR-N')
            .then((result) => {
                console.log(result.text);
                window.location="/emailsentdemo"
            }, (error) => {
                console.log(error.text);
            });
          }
  }

  render() {
 
    return (
      <React.Fragment>
        <section className="section " id="contact">
          <Container>
            <Row>
            <Col lg={8}>
            <SectionTitle
              title=" Our one-to-one session will provide"
              />
              <p>An introduction to use cases and opportunities</p>
              <p>An overview of Privacy Enhancement Technologies</p>
              <p>A demo of our Confidential Data Collaboration Platform</p>
                  <img
                      src="assets/images/arrow.png"
                      alt="data cage"
                      className="img-fluid"
                      width="90%"
                    />
              </Col>
            <Col lg={4}>
              
           
            <div
              className="hover-effect"
            >
            
                <div className="custom-form" id="demoForm">
                <form onSubmit={this.handleSubmit}>
                  <div className="panel panel-default">
                    <FormErrors formErrors={this.state.formErrors} />
                  </div>
                  <Row>
                      <Col lg={12}>
                      <div className={`form-group ${this.errorClass(this.state.formErrors.name)}`}>
                        <label htmlFor="name">Name</label>
                        <input type="text" required className="form-control" name="name"
                          placeholder="Your name ..."
                          value={this.state.name}
                          onChange={this.handleUserInput}  />
                      </div>
                      </Col>
                      <Col lg={12}>
                      <div className={`form-group ${this.errorClass(this.state.formErrors.company)}`}>
                        <label htmlFor="name">Company</label>
                        <input type="text" required className="form-control" name="company"
                          placeholder="Your company ..."
                          value={this.state.company}
                          onChange={this.handleUserInput}  />
                      </div>
                      </Col>
                      <Col lg={12}>
                      <div className={`form-group ${this.errorClass(this.state.formErrors.email)}`}>
                          <label htmlFor="email">Email address</label>
                          <input type="email" required className="form-control" name="email"
                            placeholder="Your email ..."
                            value={this.state.email}
                            onChange={this.handleUserInput}  />
                        </div>
                      </Col>
                      <Col lg={12}>
                      <div className={`form-group ${this.errorClass(this.state.formErrors.phone)}`}>
                          <label htmlFor="email">Phone number</label>
                          <input type="phone" required className="form-control" name="phone"
                            placeholder="Your phone number ..."
                            value={this.state.phone}
                            onChange={this.handleUserInput}  />
                        </div>
                      </Col>
                      <Col lg={12}>
                      <div className={`form-group ${this.errorClass(this.state.formErrors.subject)}`}>
                        <label htmlFor="subject">Subject</label>
                        <input type="text"  required className="form-control" name="subject"
                          placeholder="Your subject ..."
                          value={this.state.subject}
                          onChange={this.handleUserInput}  />
                      </div>
                      </Col>
                      <Col lg={12}>
                      <div className={`form-group ${this.errorClass(this.state.formErrors.description)}`}>
                        <label htmlFor="description">Description</label>
                        <textarea required className="form-control" name="description"
                          placeholder="Your description ..."
                          text={this.state.description}
                          rows="4"
                          onChange={this.handleUserInput}  />
                      </div>
                      </Col>
                      <Col lg={12} className="text-center">
                      
                      <br/>
                        <input
                          type="submit"
                          id="submit"
                          name="send"
                          className="submitBnt btn btn-primary"
                          value="Send Message"
                        />
                      </Col>
                    </Row>

                      
                   </form>
                 
                </div>
                </div>
                </Col>
            </Row>
            <br/><br/>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default DemoForm;
