import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

//Import Section Title
import SectionTitle from "../common/section-title";

class About extends Component {
  render() {
    return (
      <React.Fragment>
        <section className="section bg-light" id="media-header">
        <Container>
            {/* section title */}
            <SectionTitle
              title="About Datavillage"
              />
            <Container>
            <p>
                  <b>Datavillage</b> was founded on the belief that data &amp; AI must be reusable while remaining under the control of organizations and individuals at all times.<br/><br/>
                  <b>Huge amounts of data are collected</b>, duplicated and kept quiet in organizational silos. Access to them remains a complex issue legally and technically.
                  The current means to allow data sharing are currently limited and favor the copying of data, ad hoc anonymization mechanisms which raise fears of loss of control, risk of non-compliance, misuse of data and result in <b>non-sharing of data</b>.<br/><br/>
                  Datavillage's vision is to change that and <b>solve challenges that can't be solved in your own data silo !</b><br/><br/>
                  Our core values of <b>data control</b>, <b>transparency</b> and <b>reusability</b> guide our mission to enable organizations to combine their sensitive data and build better AI without compromising confidentiality and privacy.<br/><br/>
                  Datavillage revolutionizes <b>Confidential Data Collaboration</b> with unique approach combining simplicity and security.<br/><b>Certified by strict EU regulations</b>, our platform guarantees that data remains under control of data holders, used only for defined purposes with AI transparency, and always in accordance with the highest standards of confidentiality, security, and trust.
                  
                  </p>
                  <br/>
              </Container>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default About;