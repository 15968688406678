import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Navbar,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
  Container,
  Collapse,
  Button,
} from "reactstrap";


import ScrollspyNav from "./scrollSpy";

//stickey header
import "react-sticky-header/styles.css";
import StickyHeader from "react-sticky-header";

class NavbarPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenMenu: false,
    };
  }

  toggle = () => {
    this.setState({ isOpenMenu: !this.state.isOpenMenu });
  };

  render() {
    return (
      <React.Fragment>
        <StickyHeader
          header={
            <Navbar
              expand="lg"
              fixed="top"
              className="navbar-custom navbar-white"
            >
              <Container>
                <NavbarBrand className="logo text-uppercase" href="/home">
                 
                </NavbarBrand>
                <NavbarToggler onClick={this.toggle}>
                  <i className="mdi mdi-menu"></i>
                </NavbarToggler>

                <Collapse
                  id="navbarCollapse"
                  isOpen={this.state.isOpenMenu}
                  navbar
                >
                  <ScrollspyNav
                    
                    scrollDuration="800"
                    headerBackground="true"
                    activeNavClass="active"
                    className="navbar-collapse"
                  >
                    
                  <Nav className="navbar-center" navbar>
                  <NavItem>
                  <UncontrolledDropdown nav inNavbar>
                  <NavItem>
                      <NavLink href="/platform">Platform</NavLink>
                    </NavItem>
                      </UncontrolledDropdown>
                    </NavItem>
                    <NavItem>
                      <UncontrolledDropdown nav inNavbar>
                        <DropdownToggle nav caret>
                          Solutions
                        </DropdownToggle>
                        <DropdownMenu right className="dropdownmenu">
                          <DropdownItem tag={Link} to={`/collaborative-transaction-monitoring`}>
                          Transaction monitoring
                          </DropdownItem>
                          <DropdownItem tag={Link} to={`/collaborative-fraud-scoring`}>
                          Fraud scoring
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </NavItem>
                    <NavItem>
                    <UncontrolledDropdown nav inNavbar>
                        <DropdownToggle nav caret>
                          Resources
                        </DropdownToggle>
                        <DropdownMenu right className="dropdownmenu">
                          <DropdownItem tag={Link} to={`/blog`}>
                          Blog
                          </DropdownItem>
                          <DropdownItem tag={Link} to={`/news`}>
                          News
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </NavItem>
                    <NavItem>
                      <NavLink href="/company">Company</NavLink>
                    </NavItem>
                  </Nav>

                    <div className="nav-button ms-auto">
                      <Nav navbar className="navbar-end">
                        <li>
                    <Link
                      to="/contact">
                          <Button
                            color="none"
                            type="button"
                            className="btn btn-primary">
                            Contact us
                          </Button></Link>
                        </li>
                      </Nav>
                    </div>
                  </ScrollspyNav>
                </Collapse>
              </Container>
            </Navbar>
          }
          stickyOffset={-100}
        ></StickyHeader>
      </React.Fragment>
    );
  }
}

export default NavbarPage;
