import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

//Import Section Title
import SectionTitle from "../common/section-title";

class Logos extends Component {
  render() {
    return (
      <React.Fragment>
        <section className="section" id="media-header">
        <Container>
            {/* section title */}
            <SectionTitle
              title="Logos, icons &amp; names"
              />
            <Container>
              <Row className="vertical-content ">
                
                <Col lg={6}>

                <p className="mt-4"><b>Datavillage</b> is written as one word with a capital “D” and a small “v”.</p>
                <p className="mt-4"><b>Confidential Data Collaboration</b> is the official terminology of the product provided by Datavillage.</p>
                <p className="mt-4"><b>Icon</b> can only be used when there is no sufficient room for the complete logo.</p>
                <br/>
                <Link
                      to="assets/images/logos/logos.zip"
                      target="_blank"
                      className="btn btn-primary text-center "
                    >
                      Download logos &amp; icons
                    </Link>
                </Col>
                <Col lg={6}>
                <img
                    src="assets/images/logos/logos.gif"
                    alt="Datavillage logos"
                    style={{maxHeight:350+'px'}}
                  />
                </Col>
              </Row>
            </Container>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Logos;